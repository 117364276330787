import { ReactNode, useContext } from 'react'
import { useDispatch } from 'react-redux'

import { LanguageMenu } from '../LanguageMenu'
import { ThemeContext } from 'styled-components'
import { ThunkDispatch } from 'redux-thunk'
import { CheckoutHeader, CheckoutLanguage, CheckoutLogo, CheckoutWrapper } from './CheckoutContainer.styled'

import { actions, useSessionId } from 'mmfintech-checkout-commons'

type CheckoutContainerProps = {
  hideLanguageMenu?: boolean
  children?: ReactNode
}

export const CheckoutContainer = ({ hideLanguageMenu = false, children }: CheckoutContainerProps) => {
  const themeContext = useContext(ThemeContext)

  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()
  const { sessionId } = useSessionId()

  const handleLanguageChanged = (code: string): void => {
    void dispatch(actions.common.storeSelectedLanguage(sessionId, code))
  }

  return (
    <>
      <CheckoutHeader>
        <CheckoutLogo>
          <img src={themeContext.brandLogo} alt='Logo' />
        </CheckoutLogo>
        {hideLanguageMenu ? null : (
          <CheckoutLanguage>
            <LanguageMenu onChange={handleLanguageChanged} />
          </CheckoutLanguage>
        )}
      </CheckoutHeader>
      <CheckoutWrapper>{children}</CheckoutWrapper>
    </>
  )
}
