import styled from 'styled-components'

export const MessageWrapper = styled.div`
  background: #ffffff;
  margin-bottom: 2rem;
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  color: #454a54;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;

  .result-icon {
    padding: 2.2rem 3.9rem;
    border-radius: 2.4rem;
    background-color: #f4f5f6;
  }

  .content {
    margin-top: 1.5rem;
    text-align: center;
    width: 100%;

    .fail-message,
    .success-message {
      font-weight: 500;
      font-size: 1.6rem;
    }

    .fail-message {
      color: #ff3131;
    }

    .success-message {
      color: #15cf80;
    }

    .timer {
      color: #b4bfcb;
      font-size: 1.3rem;
      line-height: 1.7rem;
      margin-top: 1rem;
      margin-bottom: 4rem;
    }

    .button {
      width: 100%;
      border-radius: 0.5rem;

      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.7rem;
    }
  }
`
