import styled from 'styled-components'

export const ContentWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(320px, 400px) 1fr;
  grid-template-rows: 14.5rem auto;
  grid-template-areas: 'header header header' 'left-column main-content right-column';
  grid-row-gap: 0;

  margin: 0;
  padding: 0 2rem;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  font-family: Roboto, sans-serif;
  background-color: #fff;

  @media (min-width: 640px) {
    grid-template-rows: 12.5rem auto;
    padding: 0;
  }
`
