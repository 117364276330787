export const ClearIcon = ({ color = '#C4C4C4' }) => (
  <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 0.816162C4.47 0.816162 0 5.28616 0 10.8162C0 16.3462 4.47 20.8162 10 20.8162C15.53 20.8162 20 16.3462 20 10.8162C20 5.28616 15.53 0.816162 10 0.816162ZM10 18.8162C5.59 18.8162 2 15.2262 2 10.8162C2 6.40616 5.59 2.81616 10 2.81616C14.41 2.81616 18 6.40616 18 10.8162C18 15.2262 14.41 18.8162 10 18.8162ZM10 9.40616L13.59 5.81616L15 7.22616L11.41 10.8162L15 14.4062L13.59 15.8162L10 12.2262L6.41 15.8162L5 14.4062L8.59 10.8162L5 7.22616L6.41 5.81616L10 9.40616Z'
      fill={color}
    />
  </svg>
)
