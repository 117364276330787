import styled from 'styled-components'

export const PreviewPixStyled = styled.div`
  background: #ffffff;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
  border-radius: 1rem;

  width: 100%;
  margin: 0 1rem;
  max-width: 52rem;
  padding: 2.5rem 3.5rem;

  color: #454a54;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2rem;

  .code {
    margin: 5rem 0;
  }
`
