export const PlusIcon = ({ color = '#ffffff' }) => (
  <svg width='10' height='10' viewBox='0 0 10 10' fill={color} xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.00033 1.28931V9.45597M0.916992 5.37264H9.08366'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
