import React, { useContext, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { useHistory } from 'react-router-dom'

import { PaymentSessionStatusEnum, SupportedBank } from 'mmfintech-commons-types'
import { Button, ErrorDisplay, Input, Preloader } from 'mmfintech-portal-commons'
import { GlobalContext, copyTextToClipboard, isValidArray, tr, formatMoney, isEmptyString } from 'mmfintech-commons'
import {
  actions,
  CheckoutFlowStep,
  useCheckout,
  useFilteredBanks,
  useLanguageParam,
  useSessionId,
  useUnloadBeacon
} from 'mmfintech-checkout-commons'

import {
  BanksContainer,
  CancelContainer,
  PaymentBody,
  PaymentHead,
  PaymentSubhead,
  ProblemWrapper
} from './Checkout.styled'
import { CopyIcon, NoResultsIcon, PlusIcon, TickIcon, PencilIcon } from '../../icons'
import { BankSearchInput, CheckoutContainer, SelectBank, StepNavigation } from '../../components'
import { DepositResult } from '../DepositResult'

import settings from '../../settings'
import InfoIcon from '../../assets/icons/info-circle.svg'

const Checkout = () => {
  useLanguageParam(settings.languages)
  const { sessionId } = useSessionId()

  const {
    banks,
    banksCount,
    checkoutCountriesError,
    checkoutError,
    checkoutPay,
    checkoutPayFetching,
    session,
    sessionFetching,
    checkoutOptionsFetching
  } = useSelector(
    ({
      common: { selectedLanguage },
      checkout: {
        banks,
        banksCount,
        checkoutCountries,
        checkoutCountriesError,
        checkoutError,
        checkoutPay,
        checkoutPayFetching,
        session,
        sessionFetching,
        checkoutOptionsFetching
      }
    }) => ({
      banks,
      banksCount,
      checkoutCountries,
      checkoutCountriesError,
      checkoutError,
      checkoutPay,
      checkoutPayFetching,
      selectedLanguage,
      session,
      sessionFetching,
      checkoutOptionsFetching
    }),
    shallowEqual
  )

  const {
    amount,
    currency,
    foreignTransactionId,
    sessionStatus,
    paymentResult,
    paymentResultType,
    processingAmount,
    processingCurrency,
    showCancelButton
  } = session || {}

  const [copied, setCopied] = useState<boolean>(false)
  const [eventSubmitted, setEventSubmitted] = useState(false)
  const [formState, setFormState] = useState<number>(1)
  const { modalShow } = useContext(GlobalContext)

  const history = useHistory()
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  useUnloadBeacon({ sessionId })

  const { step, fields, status, logEvent, formValues, paymentMethod, countryCode, handlePay } = useCheckout({
    enableLogging: true,
    preselectSingleBank: true,
    preselectSingleMethod: true,
    initialStep: CheckoutFlowStep.SELECT_METHOD
  })

  const filteredBanks = useFilteredBanks((bank: SupportedBank) => handleBankSelected(bank), 0)

  const handleCopy = (value: string) => {
    copyTextToClipboard(value, () => setCopied(true))
  }

  const handleCancelClick = () => {
    if (sessionId) {
      void dispatch(actions.checkout.cancelSessionPayment(sessionId))
    }
    // logEvent('cancelled_by_payer')
    history.push('/fail')
    return false
  }

  const handleBankSelected = (bank: SupportedBank): void => {
    const id = bank ? bank.bankChoiceId : ''
    formValues.setValue('bankChoiceId', id)
    if (id) {
      logEvent('bank_selected', id)
    }
  }

  const parseResult = () => {
    try {
      return JSON.parse(paymentResult)
    } catch (error) {
      return paymentResult
    }
  }

  const prepareResponse = () =>
    sessionStatus?.value === PaymentSessionStatusEnum.IN_PROGRESS
      ? { result: parseResult(), resultType: paymentResultType, processingAmount, processingCurrency }
      : checkoutPay

  const handleSearchFocus = () => logEvent('bank_search_focus', null, true)

  const handleSearchType = () => {
    if (!eventSubmitted) {
      logEvent('bank_search_type', null, true)
      setEventSubmitted(true)
    }
  }

  useEffect(() => {
    if (checkoutError) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [checkoutError])

  useEffect(() => {
    if (isValidArray(fields)) {
      if (fields.filter(field => field.name !== 'bankChoiceId').length) {
        setFormState(1)
      } else {
        setFormState(2)
      }
    }
  }, [fields])

  return (
    <CheckoutContainer>
      {sessionFetching || checkoutOptionsFetching ? <Preloader /> : null}

      {!sessionFetching && (
        <>
          {!checkoutError && (
            <>
              <StepNavigation
                currentStep={formState}
                setCurrentStep={setFormState}
                handleClick={fields?.filter(field => field.name !== 'bankChoiceId')?.length > 0}
              />
              <PaymentHead>
                <span data-test='amount'>{formatMoney(amount, currency)}</span>
              </PaymentHead>
              {foreignTransactionId ? (
                <PaymentSubhead>
                  <span data-test='transaction-id'>{foreignTransactionId}</span>
                  <span className='copy' onClick={() => handleCopy(foreignTransactionId)}>
                    <CopyIcon color={copied ? '#15cf80' : undefined} />
                  </span>
                </PaymentSubhead>
              ) : null}
            </>
          )}

          <ErrorDisplay error={[checkoutError, checkoutCountriesError]} />

          {status === 'IDLE' &&
            (step === CheckoutFlowStep.SELECT_METHOD ||
              step === CheckoutFlowStep.SELECT_BANK ||
              step === CheckoutFlowStep.ADDITIONAL_FIELDS) && (
              <>
                {formState === 1 ? (
                  <PaymentBody>
                    <div className='bank-label'>
                      <div className='bank-label-icon'>
                        <PlusIcon color='#FFFFFF' />
                      </div>
                      {tr('CHECKOUT.PAYMENT.INFO', 'Your Info')}
                    </div>
                    {Array.isArray(fields) &&
                      fields.map((item, index) => {
                        const { name, type, defaultLabel, translationKey, required, options } = item

                        if (type.toLowerCase() === 'select') {
                          return (
                            <div key={`${name}-${index}`} className='mt-3'>
                              <Input
                                type='select'
                                options={options}
                                label={tr(translationKey, defaultLabel)}
                                {...formValues.registerInput(name)}
                                required={required}
                                hideErrorLine
                              />
                            </div>
                          )
                        }

                        if (name !== 'bankChoiceId') {
                          return (
                            <div key={`${name}-${index}`}>
                              <Input
                                type={type || 'text'}
                                label={tr(translationKey, defaultLabel)}
                                {...formValues.registerInput(name)}
                                required={required}
                                hideErrorLine
                                autoComplete='off'
                              />
                            </div>
                          )
                        }
                      })}

                    <div className='buttons mb-2'>
                      <Button
                        type='button'
                        className='button'
                        text={tr('CHECKOUT.PAYMENT.BUTTON_PAY', 'Continue')}
                        onClick={() => {
                          if (formValues.areValid()) {
                            setFormState(2)
                          }
                        }}
                        disabled={isEmptyString(paymentMethod)}
                        data-test='button-pay'
                      />
                    </div>
                  </PaymentBody>
                ) : (
                  <PaymentBody>
                    {Array.isArray(fields) &&
                      fields.map((item, index) => {
                        const { name } = item

                        if (name === 'bankChoiceId') {
                          return banks?.length > 0 ? (
                            <React.Fragment key={`${name}-${index}`}>
                              <div className='bank-label'>
                                <div className='bank-label-icon'>
                                  <TickIcon color='#FFFFFF' />
                                </div>
                                {tr('CHECKOUT.PAYMENT.BANK', 'Select Your Bank')}
                              </div>
                              <div className='bank-hint'>
                                <img src={InfoIcon} alt='' />
                                {tr(
                                  'CHECKOUT.PAYMENT.BANK_HINT',
                                  'Please prepare your online banking credentials as you might need them to complete the payment.'
                                )}
                              </div>

                              <BanksContainer>
                                {banksCount > 10 ? (
                                  <BankSearchInput
                                    countryCode={countryCode}
                                    filteredBanks={filteredBanks}
                                    handleSearchFocus={handleSearchFocus}
                                    handleSearchType={handleSearchType}
                                  />
                                ) : null}

                                <SelectBank
                                  filteredBanks={filteredBanks}
                                  selectedBankId={formValues.getValue('bankChoiceId')}
                                  onContinue={handlePay}
                                  loading={checkoutPayFetching}
                                />

                                {filteredBanks?.bankSearchText?.length > 3 && filteredBanks?.banks?.length == 0 && (
                                  <div className='no-banks-wrapper'>
                                    <NoResultsIcon />
                                    {tr('CHECKOUT.PAYMENT.NO_RESULT', 'No matching results')}
                                  </div>
                                )}
                              </BanksContainer>
                            </React.Fragment>
                          ) : null
                        }

                        // if (name === 'currency') {
                        //   return (
                        //     <Input
                        //       key={index}
                        //       type='select'
                        //       label={tr(translationKey, defaultLabel)}
                        //       {...formValues.registerInput(name)}
                        //       options={checkoutCurrencies?.map((currency: CurrencyChoice) => {
                        //         const { currencyCode, name } = currency
                        //         return { value: currencyCode, label: name }
                        //       })}
                        //       required={required}
                        //       hideErrorLine
                        //     />
                        //   )
                        // }
                      })}
                  </PaymentBody>
                )}

                {showCancelButton ? (
                  <CancelContainer onClick={handleCancelClick}>
                    {tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
                  </CancelContainer>
                ) : null}
                <ProblemWrapper onClick={() => modalShow(null)}>
                  <PencilIcon />
                  {tr('CHECKOUT.PROBLEM.TITLE', 'Report a Problem')}
                </ProblemWrapper>
              </>
            )}

          {step === CheckoutFlowStep.DEPOSIT_RESULT && (
            <DepositResult response={prepareResponse()} logEvent={logEvent} />
          )}
        </>
      )}
    </CheckoutContainer>
  )
}

export default Checkout
