import styled from 'styled-components'

export const ReportProblemStyled = styled.div`
  width: 50rem;
  max-width: 97vw;

  border-radius: 0.8rem;
  background-color: #ffffff;
  box-shadow: 0 5px 30px 0 rgba(172, 182, 191, 0.25);

  padding: 3rem 2rem;
  @media (min-width: 500px) {
    padding: 4rem 5rem 2rem;
  }

  button.submit-button {
    height: 4.4rem;
  }
`

export const ReportProblemTitle = styled.div`
  color: #454a54;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3rem;
`

export const ReportProblemSuccess = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: 1rem;

  color: #454a54;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  div {
    margin: 1.5rem 0 4rem;
  }
`
