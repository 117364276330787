export const CopyIcon = ({ color = '#c4c4c4' }) => (
  <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.67467 11.9742C2.47023 11.8576 2.30018 11.6892 2.18172 11.4858C2.06325 11.2825 2.00057 11.0515 2 10.8162V4.1495C2 3.41616 2.6 2.81616 3.33333 2.81616H10C10.5 2.81616 10.772 3.07283 11 3.48283M4.66667 7.26083C4.66667 6.78927 4.85399 6.33703 5.18743 6.00359C5.52087 5.67015 5.97311 5.48283 6.44467 5.48283H12.222C12.4555 5.48283 12.6867 5.52882 12.9024 5.61817C13.1181 5.70752 13.3141 5.83849 13.4792 6.00359C13.6443 6.1687 13.7753 6.3647 13.8647 6.58042C13.954 6.79613 14 7.02734 14 7.26083V13.0382C14 13.2717 13.954 13.5029 13.8647 13.7186C13.7753 13.9343 13.6443 14.1303 13.4792 14.2954C13.3141 14.4605 13.1181 14.5915 12.9024 14.6808C12.6867 14.7702 12.4555 14.8162 12.222 14.8162H6.44467C6.21118 14.8162 5.97997 14.7702 5.76426 14.6808C5.54854 14.5915 5.35253 14.4605 5.18743 14.2954C5.02233 14.1303 4.89136 13.9343 4.80201 13.7186C4.71266 13.5029 4.66667 13.2717 4.66667 13.0382V7.26083Z'
      stroke={color}
      strokeWidth='1.3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
