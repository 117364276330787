import { isLocalhost } from 'mmfintech-commons'
import { registerLocale } from 'react-datepicker'

import ja from 'date-fns/locale/ja'

registerLocale('ja', ja)

const host = typeof window !== 'undefined' ? window.location.host : ''

const isDevelopment = host.indexOf('-dev.') > -1
const isSandbox = host.indexOf('-sandbox.') > -1
export const isProduction = typeof window !== 'undefined' && !isLocalhost && !isSandbox && !isDevelopment

const settings = {
  backendForLocalhost: 'https://pay-dev.tomopay.net',
  // backendForLocalhost: 'https://pay-sandbox.tomopay.net',
  languages: ['en', 'ja']
}

export default settings
