export const NoResultsIcon = ({ color = '#15CF80' }) => (
  <svg width='34' height='32' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.25033 6.06608L26.2503 27.0661M0.75 16.5662C0.75 18.536 1.13799 20.4865 1.89181 22.3064C2.64563 24.1263 3.75052 25.7799 5.1434 27.1728C6.53628 28.5656 8.18986 29.6705 10.0097 30.4244C11.8296 31.1782 13.7802 31.5662 15.75 31.5662C17.7198 31.5662 19.6704 31.1782 21.4903 30.4244C23.3101 29.6705 24.9637 28.5656 26.3566 27.1728C27.7495 25.7799 28.8544 24.1263 29.6082 22.3064C30.362 20.4865 30.75 18.536 30.75 16.5662C30.75 14.5963 30.362 12.6458 29.6082 10.8259C28.8544 9.00603 27.7495 7.35244 26.3566 5.95956C24.9637 4.56668 23.3101 3.46179 21.4903 2.70797C19.6704 1.95415 17.7198 1.56616 15.75 1.56616C13.7802 1.56616 11.8296 1.95415 10.0097 2.70797C8.18986 3.46179 6.53628 4.56668 5.1434 5.95956C3.75052 7.35244 2.64563 9.00603 1.89181 10.8259C1.13799 12.6458 0.75 14.5963 0.75 16.5662Z'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
