import styled from 'styled-components'

export const CheckoutHeader = styled.div`
  grid-area: header;

  display: grid;
  grid-template-columns: 1fr minmax(320px, 400px) 1fr;
  grid-template-rows: 4.5rem auto;
  grid-template-areas: 'l-empty right-header r-empty' 'l-empty main-header r-empty';

  @media (min-width: 640px) {
    display: grid;
    grid-template-columns: 1fr minmax(320px, 400px) 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'left-header main-header right-header';
  }
`

export const CheckoutLogo = styled.div`
  grid-area: main-header;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 3rem;

  img {
    max-width: 20rem;
  }

  @media (min-width: 640px) {
    margin-bottom: 5rem;
  }
`

export const CheckoutLanguage = styled.div`
  grid-area: right-header;

  display: grid;
  place-items: flex-end flex-end;

  @media (min-width: 640px) {
    display: grid;
    place-items: center;
  }
`

export const CheckoutWrapper = styled.div`
  grid-area: main-content;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 2.4rem;

  .err-msg-wrapper {
    justify-content: center;
  }
`
