export const SearchIcon = ({ color = '#c4c4c4' }) => (
  <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.76 11.0862L17.49 16.8162L16 18.3062L10.27 12.5762C9.2 13.3462 7.91 13.8162 6.5 13.8162C2.91 13.8162 0 10.9062 0 7.31616C0 3.72616 2.91 0.816162 6.5 0.816162C10.09 0.816162 13 3.72616 13 7.31616C13 8.72616 12.53 10.0162 11.76 11.0862ZM6.5 2.81616C4.01 2.81616 2 4.82616 2 7.31616C2 9.80616 4.01 11.8162 6.5 11.8162C8.99 11.8162 11 9.80616 11 7.31616C11 4.82616 8.99 2.81616 6.5 2.81616Z'
      fill={color}
    />
  </svg>
)
