import styled from 'styled-components'

export const ModalWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(53, 53, 53, 0.8);
  backdrop-filter: blur(5px);

  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 5000;

  display: flex;
  align-items: center;
  justify-content: center;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
  }
`

export const ModalContainer = styled.div<{ checkWidth: number }>`
  margin: 0;
  padding: 0;
  height: fit-content;

  display: inline-grid;
  grid-template-areas: 'main';
  grid-template-columns: 1fr;
  grid-gap: 0;

  .popup-main-content {
    grid-area: main;
    margin: 5rem 0 2rem;
    position: relative;
    background: #ffffff;
    box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);
    border-radius: 0.8rem;
  }

  .popup-close-button-inline {
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 50px;
    height: 50px;
    z-index: 101;
  }

  .popup-close-button {
    display: none;
    cursor: pointer;
    grid-area: right;
    border-radius: 100%;
    margin-left: auto;
    width: 50px;
    height: 50px;
    z-index: 101;
  }

  &.scrollable {
    .overflowing & {
      height: 98vh;
    }

    .popup-main-content {
      height: auto;
      overflow-y: auto;

      ::-webkit-scrollbar {
        width: 0.5rem;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
      }
      ::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.3);
        outline: none;
      }
    }
  }

  @media (min-width: ${({ checkWidth }) => `${checkWidth}px`}) {
    grid-template-areas: 'left main right';
    grid-template-columns: 50px 1fr 50px;

    .popup-close-button-inline {
      display: none;
    }

    .popup-close-button {
      display: block;
    }

    &.icon-speedy-special {
      .popup-main-content {
        margin-top: 2rem;
      }

      .popup-close-button {
        margin-left: -3rem;
      }
    }
  }
`
