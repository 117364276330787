export const SuccessIcon = ({ color = '#15CF80' }) => (
  <svg width='57' height='44' viewBox='0 0 57 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.92383 26.9883L17.2263 41.0156L41.8339 16.8814M53.9946 4.95459L48.1468 10.6899'
      stroke={color}
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
