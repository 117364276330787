export const ArrowIcon = ({ color = '#454A54' }) => (
  <svg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1 1.81616L7 7.81616L1 13.8162'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
