import styled from 'styled-components'

export const MenuWrapper = styled.div`
  display: flex;
  color: #454a54;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.7rem;
  position: relative;
  justify-content: flex-end;
`

export const MenuContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  max-width: 12rem;
  padding-left: 1.5rem;

  .selected {
    width: 100%;
  }

  .arrow {
    margin: 0 1.2rem 0 1rem;
    width: 11px;
    svg * {
      stroke: #000;
    }
  }
`

export const MenuDropDown = styled.div`
  top: 2.5rem;

  width: 16rem;
  padding: 1rem 0;

  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 5px 30px rgba(172, 182, 191, 0.25);

  z-index: 1001;
  position: absolute;

  display: none;
  &.opened {
    display: block;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      height: 4rem;
      color: #454a54;
      padding: 0 2rem;

      :hover {
        background: #e7ebee;
      }
    }
  }
`
