import { configuration } from 'mmfintech-checkout-commons'
import { isBase64EncodedImage } from 'mmfintech-commons'

import { SupportedBank } from 'mmfintech-commons-types'

import BankIcon from '../../assets/icons/bank-icon.svg'

type BankLogoProps = {
  bank: SupportedBank
}

export const BankLogo = ({ bank }: BankLogoProps) => {
  if (bank && typeof bank.logoUrl === 'string' && bank.logoUrl.length) {
    if (isBase64EncodedImage(bank.logoUrl) || bank.logoUrl.substring(0, 4) === 'http') {
      return <img className='bankLogo' src={bank.logoUrl} alt={`${bank.name} logo`} />
    }
    // return <img className='bankLogo' src={`/logos/banks/${bankId}.png`} alt={`${name} logo`} />
    return (
      <img className='bankLogo' src={`${configuration.readBackendConfig()}${bank.logoUrl}`} alt={`${bank.name} logo`} />
    )
  }
  return <img className='bankLogo' src={BankIcon} alt={`${bank.name} logo`} />
}
