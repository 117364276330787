export const FailureIcon = ({ color = '#FF3131' }) => (
  <svg width='47' height='46' viewBox='0 0 47 46' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M42.7261 3.73486L38.7716 7.68939M3.4082 43.0529C3.4082 43.0529 20.3288 26.1322 32.9688 13.4923M3.4082 7.6866L38.7744 43.0529'
      stroke={color}
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
