import cn from 'classnames'

import { BankLogo } from './BankLogo'
import {
  SelectBankItemArrow,
  SelectBankItemLogo,
  SelectBankItemName,
  SelectBankItemStyled
} from './SelectBankItem.styled'

import { SupportedBank } from 'mmfintech-commons-types'
import { ArrowIcon } from './ArrowIcon'
import { ThreeCircles } from 'react-loader-spinner'

type SelectBankItemProps = {
  bank: SupportedBank
  loading?: boolean
  selected?: boolean
  onClick: () => void
}

export const SelectBankItem = ({ bank, loading, selected, onClick }: SelectBankItemProps) => {
  const { name, nameEnglish, countryCode } = bank

  const showBothDomesticAndEnglishName = ['JPN', 'KOR'].includes(countryCode)
  const domesticName = showBothDomesticAndEnglishName ? (nameEnglish ? name : null) : null
  const englishName = showBothDomesticAndEnglishName && nameEnglish ? nameEnglish : name

  return (
    <SelectBankItemStyled onClick={onClick} className={cn({ loading, selected })}>
      <div className='select-bank-item-wrapper'>
        <SelectBankItemLogo>
          <div className='inner'>
            <BankLogo bank={bank} />
          </div>
        </SelectBankItemLogo>

        <SelectBankItemName>
          {domesticName ? <span>{domesticName}</span> : null}
          {englishName && englishName !== domesticName ? <span data-test='bank-name'>{englishName}</span> : null}
        </SelectBankItemName>

        <SelectBankItemArrow>
          {loading ? (
            <ThreeCircles width='3rem' height='3rem' color='#15cf80' />
          ) : (
            <ArrowIcon color={selected ? '#15cf80' : '#454a54'} />
          )}
        </SelectBankItemArrow>
      </div>
    </SelectBankItemStyled>
  )
}
