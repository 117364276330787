import styled from 'styled-components'

export const StepNavigationStyled = styled.div`
  display: flex;
  gap: 1rem;
  max-width: 45rem;
  width: 110%;
  align-items: center;
  padding-bottom: 3rem;

  .break-element {
    height: 1px;
    background-color: #85a0ad;
    flex: 1;
  }

  .nav-element {
    width: fit-content;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: #454a54;

    &:has(.element-status.passed) {
      cursor: pointer;
      &:hover {
        color: #15cf80;
      }
    }

    .element-status {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      color: #c4c4c4;
      background-color: #f4f5f6;
      margin-right: 0.4rem;

      &.selected {
        color: #ffffff;
        background-color: #15cf80;
      }

      &.passed {
        background-color: unset;
        border: solid 1px #15cf80;
        cursor: pointer;
      }
    }
  }
`
