import styled from 'styled-components'

export const SelectBankItemStyled = styled.div`
  position: relative;
  cursor: pointer;

  width: 100%;
  height: 100%;

  min-height: 7rem;

  background-color: #f4f5f6;
  border: 3px solid #ffffff;
  border-radius: 11px;

  color: #454a54;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: normal;

  .select-bank-item-wrapper {
    display: grid;
    grid-template-columns: 5rem 1fr 1.4rem;
    grid-gap: 1rem;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid #f4f5f6;
  }

  &.selected {
    background-color: rgba(21, 207, 128, 0.1);
    border-color: #ffffff;

    .select-bank-item-wrapper {
      border-color: rgba(21, 207, 128);
    }
  }

  &:hover {
    border-color: rgba(21, 207, 128, 0.2);

    .select-bank-item-wrapper {
      border-color: rgba(21, 207, 128);
    }

    svg {
      filter: brightness(0) saturate(100%) invert(78%) sepia(21%) saturate(6388%) hue-rotate(100deg) brightness(92%)
        contrast(84%);
    }
  }

  &.loading .select-bank-item-wrapper{
    grid-template-columns: 5rem 1fr 3.5rem;
  }
`

export const SelectBankItemLogo = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .inner {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    text-align: center;
    background-color: white;
    border-radius: 8px;
  }

  img,
  svg {
    max-width: 5rem;
    max-height: 5rem;
    border-radius: 8px;
  }
`

export const SelectBankItemName = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
`

export const SelectBankItemArrow = styled.div`
  display: inline-flex;
  align-items: center;
  height: 100%;
`
