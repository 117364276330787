import { Input } from 'mmfintech-portal-commons'
import { ClearIcon, SearchIcon } from '../../icons'
import { BankSearchInputStyled } from './BankSearchInput.styled'

import { tr } from 'mmfintech-commons'

export const BankSearchInput = ({ countryCode, filteredBanks, handleSearchFocus, handleSearchType }) => {
  return (
    <BankSearchInputStyled>
      <SearchIcon />
      <Input
        key='bank-search'
        type='text'
        name='bank-search'
        data-test='bank-search-input'
        maxLength={50}
        value={filteredBanks.bankSearchText}
        onChange={(_name: string, value: string) => {
          filteredBanks.setBankSearchText(value)
          if (value?.length) {
            handleSearchType()
          }
        }}
        onFocus={handleSearchFocus}
        placeholder={
          countryCode === 'DEU'
            ? tr('CHECKOUT.PAYMENT.BANK_SEARCH_DEU', 'Search by bank name or BLZ')
            : tr('CHECKOUT.PAYMENT.BANK_SEARCH', 'Search by bank name')
        }
        hideErrorLine
        autoComplete='off'
      />
      {filteredBanks?.bankSearchText?.length > 3 && (
        <div className='search-input-clear' onClick={() => filteredBanks.setBankSearchText('')}>
          <ClearIcon />
        </div>
      )}
    </BankSearchInputStyled>
  )
}
