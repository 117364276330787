import { BanksContainer } from './SelectBank.styled'
import { SelectBankItem } from '../SelectBankItem'

import { shallowEqual, useSelector } from 'react-redux'

import { SupportedBank } from 'mmfintech-commons-types'
import { UseFilteredBanksResult } from 'mmfintech-checkout-commons'

interface SelectBankProps {
  filteredBanks: UseFilteredBanksResult
  selectedBankId: string
  onContinue: () => void
  loading?: boolean
}

export const SelectBank = ({ filteredBanks, selectedBankId, onContinue, loading }: SelectBankProps) => {
  const { banksLoaded } = useSelector(
    ({ checkout: { banksLoaded } }) => ({
      banksLoaded
    }),
    shallowEqual
  )

  const setSelected = (bank: SupportedBank) => {
    if (!loading) {
      if (selectedBankId === bank.bankChoiceId) {
        onContinue()
      } else {
        filteredBanks.handleSelectBank(bank)
      }
    }
  }

  if (banksLoaded && Array.isArray(filteredBanks.banks) && filteredBanks.banks.length) {
    return (
      <BanksContainer>
        {filteredBanks.banks.map((bank: SupportedBank) => (
          <SelectBankItem
            key={bank.bankChoiceId}
            bank={bank}
            loading={selectedBankId === bank.bankChoiceId && loading}
            selected={selectedBankId === bank.bankChoiceId}
            onClick={() => setSelected(bank)}
          />
        ))}
      </BanksContainer>
    )
  }

  return null
}
