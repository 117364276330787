import { tr } from 'mmfintech-commons'
import { StepNavigationStyled } from './StepNavigation.styled'
import { TickIcon } from '../../icons'
import cn from 'classnames'

export const StepNavigation = ({
  currentStep = 1,
  setCurrentStep,
  handleClick = true
}: {
  currentStep?: number
  setCurrentStep: (val: number) => void
  handleClick: boolean
}) => {
  return (
    <StepNavigationStyled>
      <div
        className='nav-element'
        onClick={() => {
          if (currentStep > 1 && handleClick) {
            setCurrentStep(1)
          }
        }}>
        <div className={cn('element-status', { selected: currentStep == 1, passed: currentStep > 1 })}>
          {currentStep > 1 ? <TickIcon /> : '1'}
        </div>
        <div className='element-text'>{tr('CHECKOUT.NAVIGATION.INFO', 'Your Info')}</div>
      </div>
      <div className='break-element'></div>
      <div className='nav-element'>
        <div className={cn('element-status', { selected: currentStep == 2, passed: currentStep > 2 })}>
          {currentStep > 2 ? <TickIcon /> : '2'}
        </div>
        <div className='element-text'>{tr('CHECKOUT.NAVIGATION.DETAILS', 'Payment Details')}</div>
      </div>
      <div className='break-element'></div>

      <div className='nav-element'>
        <div className={cn('element-status', { selected: currentStep == 3, passed: currentStep > 3 })}>
          {currentStep > 3 ? <TickIcon /> : '3'}
        </div>
        <div className='element-text'>{tr('CHECKOUT.NAVIGATION.FINAL', 'Final Steps')}</div>
      </div>
    </StepNavigationStyled>
  )
}
