import styled from 'styled-components'

export const BankSearchInputStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 1px solid #e1e5ea;
  margin-bottom: 2.4rem;

  .input-wrapper {
    margin: 0;
    & > div {
      border: none;
      border-radius: 0;
    }
  }

  &:has( input:focus ) {
    border-color: #15CF80;
  }

  .search-input-clear {
    margin-right: 1rem;
    cursor: pointer;
    &:hover {
      filter: brightness(0) saturate(100%) invert(54%) sepia(91%) saturate(402%) hue-rotate(103deg) brightness(100%)
        contrast(93%);
    }
  }
`
