import { Button } from 'mmfintech-portal-commons'
import { FailureIcon } from '../../icons'
import { AlertContent, AlertWrapper } from './ErrorFallback.styled'
import { tr } from 'mmfintech-commons'

export const ErrorFallback = ({ resetErrorBoundary = null }) => (
  <AlertWrapper>
    <AlertContent>
      <div className='fail-icon'>
        <FailureIcon />
      </div>

      <p>{tr('CHECKOUT.ERROR.TITLE', 'An unexpected error occurred')}</p>

      {resetErrorBoundary ? (
        <Button
          color='alternative'
          onClick={resetErrorBoundary}
          text={tr('CHECKOUT.ERROR.BUTTON_TEXT', 'Go back to the home page')}
        />
      ) : null}
    </AlertContent>
  </AlertWrapper>
)
