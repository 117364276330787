import styled from 'styled-components'

export const BanksContainer = styled.div`
  border: 1px solid #e1e5ea;
  border-radius: 1rem;
  padding: 1.6rem 1.6rem 2.4rem;
  width: 100%;
`

export const PaymentHead = styled.div`
  background: #15cf80;
  border-radius: 0.8rem 0.8rem 0 0;
  width: 100%;
  padding: 1.5rem 1.6rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  color: #ffffff;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const PaymentSubhead = styled.div`
  background: #f4f5f6;
  border-radius: 0 0 0.8rem 0.8rem;
  padding: 1rem 1.6rem;
  width: 100%;

  color: #818389;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  justify-content: space-between;

  .copy {
    cursor: pointer;
  }
`

export const PaymentBody = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 3rem;

  .bank-label {
    color: #454a54;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    gap: 0.8rem;

    .bank-label-icon {
      width: 1.9rem;
      height: 1.9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #c4c4c4;
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .bank-hint {
    color: #a3b1bf;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1rem;
    padding: 0 2rem;

    img {
      margin-right: 5px;
    }
  }

  .buttons {
    margin-top: 5rem;

    button {
      border-radius: 0.8rem;

      height: 4.4rem;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .no-banks-wrapper {
    color: #c4c4c4;
    font-size: 1.2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    padding-top: 1.6rem;
  }
`

export const CancelContainer = styled.div`
  display: grid;
  place-items: center;
  margin-top: 1rem;
  height: 4.4rem;
  width: 100%;
  cursor: pointer;

  color: #8f99a3;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    color: #454a54;
  }
`

export const ProblemWrapper = styled.div`
  margin: 2rem 0 1rem;
  text-align: center;
  cursor: pointer;
  font-size: 1.4rem;
  border-bottom: 1px solid #c4c4c4;
  font-style: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #454a54;

  svg {
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 6px;
  }

  &:hover {
    border-bottom-color: #15cf80;

    svg > * {
      fill: #15cf80;
    }
  }
`
